import { clamp } from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'

import { isProd } from 'utils/env'
import { aioKeySelector, usernameSelector } from 'selectors/users'


const { actions, reducer } = createSlice({
  name: 'firmware_wizard',
  initialState: {
    ssid: '',
    password: '',
    brightness: 0.2
  },
  reducers: {
    // input: wifi ssid
    setSsid: (state, { payload: ssid }) => ({ ...state, ssid }),
    // input: wifi password
    setPassword: (state, { payload: password }) => ({ ...state, password }),
    // input: pixel brightness
    setBrightness: (state, { payload: brightness }) => ({ ...state, brightness }),
    // output: template to json string
  },
})

export const
  { setSsid, setPassword, setBrightness } = actions,

  wizardState = state => state.firmware_wizard,

  ssid = state => wizardState(state).ssid,
  password = state => wizardState(state).password,
  brightness = state => wizardState(state).brightness,

  secretsJson = createSelector(
    wizardState, usernameSelector, aioKeySelector,
    (state, username, aioKey) => {
      if(!aioKey) { return }

      const
        { ssid, password, brightness} = state,

        // make brightness a float between 0.0 and 1.0
        parsedBrightness = parseFloat(brightness),
        clampedBrightness =  isFinite(parsedBrightness)
          ? clamp(parsedBrightness, 1.0)
          : 0.2,

        // inject special io_url prop when not on prod
        ioUrlProperty = isProd
          ? {}
          : { io_url: import.meta.env.VITE_IO_HOST },

        // render our data into the JSON template
        secretsJson = {
          io_username: username,
          io_key: aioKey,
          network_type_wifi: {
            network_ssid: ssid,
            network_password: password
          },
          status_pixel_brightness: clampedBrightness,
          ...ioUrlProperty
        }

      // pretty print the json to a string
      return (ssid && password) ? JSON.stringify(secretsJson, null, 2) : null
    }
  )

export default reducer
