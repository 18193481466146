import { find } from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'


const { actions, reducer } = createSlice({
  name: 'boards',
  initialState: {
    boards: {}
  },
  reducers: {
    setBoards: (state, { payload: boards }) => ({ ...state, boards }),
  },
})

export const
  // ACTIONS
  { setBoards } = actions,

  // SELECTORS
  boardsState = state => state.boards,

  selectAllBoards = createSelector([boardsState], state => state.boards),

  // QUERIES
  getBoardByName = (state, boardName) => {
    const
      allBoards = selectAllBoards(state),
      board = allBoards[boardName]

    return board
  },

  getUARTPinsForBoard = (state, boardName) => {
    const
      pins = getBoardByName(state,boardName)?.components.digitalPins,
      tx = find(pins, "uartTx")?.name,
      rx = find(pins, "uartRx")?.name

    // both or nothing
    return (tx && rx) ? { tx, rx } : null
  }


export default reducer
